export const API_URL = process.env.GATSBY_API_URL
export const JWT_ISSUER = process.env.GATSBY_JWT_ISSUER
export const JWT_AUDIENCE = process.env.GATSBY_JWT_AUDIENCE
export const BASE_TITLE = process.env.GATSBY_BASE_TITLE
export const CHAT_URL = process.env.GATSBY_KIWI_URL
export const CHANNELS = process.env.GATSBY_CHANNELS
export const RADIO_URL = process.env.GATSBY_RADIO_URL
export const RADIO_WS = process.env.GATSBY_RADIO_WS
export const DEFAULT_IMG_URL = process.env.GATSBY_DEFAULT_IMG_URL
export const VERCEL_PROJECT_ID = process.env.GATSBY_VERCEL_PROJECT_ID
export const countries = [
  'Albania',
  'Alemania',
  'Andorra',
  'Angola',
  'Antigua y Barbuda',
  'Arabia Saudita',
  'Argelia',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaiyán',
  'Bahamas',
  'Bahrein',
  'Bangladesh',
  'Barbados',
  'Belarús',
  'Belice',
  'Benin',
  'Bhután',
  'Bolivia',
  'Bosnia y Herzegovina',
  'Botswana',
  'Brasil',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Bélgica',
  'Cabo Verde',
  'Camboya',
  'Camerún',
  'Canadá',
  'Chad',
  'Chequia',
  'Chile',
  'China',
  'Chipre',
  'Colombia',
  'Comoras',
  'Congo',
  'Corea del Norte',
  'Corea del Sur',
  'Costa Rica',
  'Croacia',
  'Cuba',
  "Côte d'Ivoire",
  'Dinamarca',
  'Djibouti',
  'Dominica',
  'Ecuador',
  'Egipto',
  'El Salvador',
  'Emiratos Árabes Unidos',
  'Eritrea',
  'Eslovaquia',
  'Eslovenia',
  'España',
  'Estados Unidos',
  'Estonia',
  'Eswatini',
  'Etiopía',
  'Fiji',
  'Filipinas',
  'Finlandia',
  'Francia',
  'Gabón',
  'Gambia',
  'Georgia',
  'Ghana',
  'Granada',
  'Grecia',
  'Guatemala',
  'Guinea',
  'Guinea Ecuatorial',
  'Guinea-Bissau',
  'Guyana',
  'Haití',
  'Honduras',
  'Hungría',
  'India',
  'Indonesia',
  'Iraq',
  'Irlanda',
  'Irán',
  'Islandia',
  'Islas Cook',
  'Islas Feroe',
  'Islas Marshall',
  'Islas Salomón',
  'Israel',
  'Italia',
  'Jamaica',
  'Japón',
  'Jordania',
  'Kazajstán',
  'Kenya',
  'Kirguistán',
  'Kiribati',
  'Kuwait',
  'Laos',
  'Lesotho',
  'Letonia',
  'Liberia',
  'Libia',
  'Lituania',
  'Luxemburgo',
  'Líbano',
  'Macedonia del Norte',
  'Madagascar',
  'Malasia',
  'Malawi',
  'Maldivas',
  'Malta',
  'Malí',
  'Marruecos',
  'Mauricio',
  'Mauritania',
  'Micronesia',
  'Mongolia',
  'Montenegro',
  'Mozambique',
  'Myanmar',
  'México',
  'Moldavia',
  'Mónaco',
  'Namibia',
  'Nauru',
  'Nepal',
  'Nicaragua',
  'Nigeria',
  'Niue',
  'Noruega',
  'Nueva Zelanda',
  'Níger',
  'Omán',
  'Pakistán',
  'Palau',
  'Panamá',
  'Papua Nueva Guinea',
  'Paraguay',
  'Países Bajos',
  'Perú',
  'Polonia',
  'Portugal',
  'Qatar',
  'Reino Unido',
  'República Centroafricana',
  'República Democrática del Congo',
  'República Dominicana',
  'Rumania',
  'Russia',
  'Rwanda',
  'Saint Kitts y Nevis',
  'Samoa',
  'San Marino',
  'San Vicente y las Granadinas',
  'Santa Lucía',
  'Santo Tomé y Príncipe',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leona',
  'Singapur',
  'Siria',
  'Somalia',
  'Sri Lanka',
  'Sudáfrica',
  'Sudán',
  'Sudán del Sur',
  'Suecia',
  'Suiza',
  'Surinam',
  'Tailandia',
  'Tanzania',
  'Tayikistán',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad y Tabago',
  'Turkmenistán',
  'Turquía',
  'Tuvalu',
  'Túnez',
  'Ucrania',
  'Uganda',
  'Uruguay',
  'Uzbekistán',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
] as const
export const VERIFICATION_CODE_LENGTH = 26
