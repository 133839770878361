exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acerca-tsx": () => import("./../../../src/pages/acerca.tsx" /* webpackChunkName: "component---src-pages-acerca-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app/[...].tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manual-tsx": () => import("./../../../src/pages/manual.tsx" /* webpackChunkName: "component---src-pages-manual-tsx" */),
  "component---src-pages-registro-tsx": () => import("./../../../src/pages/registro.tsx" /* webpackChunkName: "component---src-pages-registro-tsx" */),
  "component---src-pages-reglas-tsx": () => import("./../../../src/pages/reglas.tsx" /* webpackChunkName: "component---src-pages-reglas-tsx" */)
}

